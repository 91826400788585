.dashboardLayout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .columnOne,
  .columnTwo,
  .columnThree {
    flex: 1; /* Makes each column take up the same amount of width */
    padding: 20px;
    border: 1px solid #ccc; /* Optional, just to visualise the columns */
    border-radius: 15px;
    margin: 5px;
  }
  

  .dashboardTable{
    font-weight: 600;
    font-size: 14px;
  }

  .dashboardTable th{
    color: #141414;
    font-size: 16px;
    cursor: pointer;
}

  .dashboardTable a{
    color: #141414;
    text-decoration: none;;
  }

  .dashboardTable a:hover{
    color: orange;
  }
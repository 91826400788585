.form-container {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .button {
    margin: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #f0f0f0;
  }
  

  /* Table styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  /* Table header styles */
  th {
    background-color: #f2f2f2;
    text-align: left;
    padding: 10px;
  }
  
  /* Table row styles */
  tr {
    border-bottom: 1px solid #dddddd;
  }
  
  /* Alternate row background */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Table cell styles */
  td {
    padding: 10px;
    text-align: left;
  }
  
  /* Hover effect on rows */
  tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Button styling */
  button {
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    background-color: #FF8F15;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 700;
  }
  
  button:hover {
    background-color: #000000;
    color: white;
  }
  
  /* Style for Delete button */
  button.delete {
    background-color: #f44336;
  }
  
  button.delete:hover {
    background-color: #da190b;
  }
  
  /* Style for Edit button */
  button.edit {
    background-color: #f0ad4e;
  }
  
  button.edit:hover {
    background-color: #ec8e13;
  }
  
/* General Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  /* Layout Wrapper */
  div {
    box-sizing: border-box;
  }
  
  /* Side Bar */
  .sidebar {
    width: 200px;
    border-right: 1px solid #ccc;
    position: relative; /* Allows us to set child elements absolutely within it */
  }
  
  /* Logo */
  .header img {
    width: 100px;
    height: auto;
    margin: 20px;
  }
  
  /* Navigation */
  nav ul {
    list-style: none;
    padding-left: 0;
  }
  
  nav ul li {
    margin: 15px 0;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    padding: 10px;
    display: block;
  }
  
  /* Hover Effects */
  nav ul li a:hover {
    background-color: #ccc;
    border-radius: 5px;
  }
  
  .user-greeting {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 10px;
    width: 200px; /* Make sure this matches the width of your sidebar */
    border-top: 1px solid #ccc;
    text-align: center;
    transition: background-color 0.3s ease; /* This makes the change smooth */
    color: #141414;
    font-weight: 600;
  }
  
  /* Styling for the text "Hi, {userName}" */
.user-greeting span:first-child {
    margin-right: 10px;
  }
  
  /* Styling for Font Awesome User Icon */
  .user-greeting .fa-user {
    color: #333;
    transition: color 0.3s ease;
  }
  
  /* Hover effects */
  .user-greeting:hover {
    background-color: #eaeaea;
  }
  
  .user-greeting:hover .fa-user {
    color: #007bff;
  }
  
  /* Styling for the link to keep it neat */
  .user-greeting a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Content Area */
  .content-area {
    flex: 1;
    padding: 20px;
  }
  
.customerLayout {
    display: flex;
    justify-content: space-between;
  }
  
  .left {
    float: left;
    width: 20%;
    padding: 25px;
  }

  .middle{
    width: 40%;
    padding: 25px;
  }
  
  .right {
    float: right;
    width: 40%;
    padding: 25px;
  }
  
  .scoreChart{
    width: 500px;
  }


  .infoPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .infoPopupContent {
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 100%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    width: 50%;
  }
  
  .infoPopupContent h3 {
    margin-top: 0;
  }
  
  .infoPopupContent label {
    display: block;
    margin-bottom: 8px;
  }
  
  .infoPopupContent input {
    width: 100%;
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .infoPopupContent button {
    padding: 10px 20px;
    background-color: #FF8F15;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .infoPopupContent button:hover{
    background-color: #141414;
  }


  .title{
    color: #FF8500;
    font-weight: 700;
  }

  .textareaClass{
    resize: none;
    overflow: auto;
    height: auto;
    width: 100%;
    font-family: arial;
  }

  .workLogViewHeaders{
    font-weight: bold;
    font-size: 18px;
  }
  .workLogViewBox{
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
  }